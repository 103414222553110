import React from "react";
import logo from '../../../src/img/logo.png'
import { Link } from "react-router-dom";

const TelegramComponent = () => {
  return (


    <>
      <div className="pt-10">
        <div className="grid grid-rows-3 md:grid-rows-1 grid-flow-col space-x-4 items-center justify-center">


          {/* 01 */}
          <div className="mb-4 w-80 h-48">
            <div className="max-w-xs rounded-md shadow-md dark:bg-gray-900 dark:text-gray-100">

              {/* ..............................................add qr code here..............................*/}
              {/* <img src="https://source.unsplash.com/random/300x300/?2" alt="" className="object-cover object-center w-full rounded-t-md h-72 dark:bg-gray-500" /> */}


              <div className="flex flex-col justify-between p-6 space-y-8">
                <div className="space-y-2">
                  <h2 className="text-2xl font-bold tracki text-navy-700 dark:text-white text-center">Free Channel</h2>
                </div>
                <Link to='https://t.me/syntiumalgofree'>
                  <button type="button" className="flex items-center justify-center w-full p-3 bg-blue-500 text-white font-bold dark:text-white py-2 px-4 rounded">Join Channel</button>
                </Link>
              </div>
            </div>
          </div>


          {/* 02 */}
          <div className="mb-4 w-80 h-48">
            <div className="max-w-xs rounded-md shadow-md dark:bg-gray-900 dark:text-gray-100">

              {/* ..............................................add qr code here..............................*/}
              {/* <img src="https://source.unsplash.com/random/300x300/?2" alt="" className="object-cover object-center w-full rounded-t-md h-72 dark:bg-gray-500" /> */}


              <div className="flex flex-col justify-between p-6 space-y-8">
                <div className="space-y-2">
                  <h2 className="text-2xl font-bold tracki text-navy-700 dark:text-white text-center">Get notified</h2>
                </div>
                <Link to='https://t.me/lOrn8M0q1QcxNTNk'>
                  <button type="button" className="flex items-center justify-center w-full p-3 bg-blue-500 text-white font-bold dark:text-white py-2 px-4 rounded">Join Channel</button>
                </Link>
              </div>
            </div>
          </div>


          {/* 03 */}
          <div className="mb-4 w-80 h-48">
            <div className="max-w-xs rounded-md shadow-md dark:bg-gray-900 dark:text-gray-100">

              {/* ..............................................add qr code here..............................*/}
              {/* <img src="https://source.unsplash.com/random/300x300/?2" alt="" className="object-cover object-center w-full rounded-t-md h-72 dark:bg-gray-500" /> */}


              <div className="flex flex-col justify-between p-6 space-y-8">
                <div className="space-y-2">
                  <h2 className="text-2xl font-bold tracki text-navy-700 dark:text-white text-center">Trading room</h2>
                </div>
                <Link to='https://t.me/+lOrn8M0q1QcxNTNk'>
                  <button type="button" className="flex items-center justify-center w-full p-3 bg-blue-500 text-white font-bold dark:text-white py-2 px-4 rounded">Join Channel</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TelegramComponent;
