import React from 'react'
import ScreenWidget from './components/ScreenWidget';
import EconomicCalender from './components/EconomicCalender';


// ICONS
import { IoMdHome } from "react-icons/io";
import { IoDocuments } from "react-icons/io5";
import { MdBarChart, MdDashboard } from "react-icons/md";
// widget
import Widget from "components/widget/Widget";
// alertpairs
import TaskCard from "views/admin/default/components/TaskCard";

export default function index() {
  return (
    <>
      {/* Card widget */}

      <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-2 2xl:grid-cols-2 3xl:grid-cols-2">
        {/* <Widget
          icon={<MdBarChart className="h-7 w-7" />}
          title={"Earnings"}
          subtitle={"$0.00"}
        /> */}
        {/* <Widget
          icon={<IoDocuments className="h-6 w-6" />}
          title={"Spend this month"}
          subtitle={"$0.00"}
        /> */}
        {/* <Widget
          icon={<MdBarChart className="h-7 w-7" />}
          title={"Sales"}
          subtitle={"$0.00"}
        /> */}
        {/* <Widget
          icon={<MdDashboard className="h-6 w-6" />}
          title={"Your Balance"}
          subtitle={"$0.00"}
        /> */}
        {/* <Widget
          icon={<MdBarChart className="h-7 w-7" />}
          title={"New Tasks"}
          subtitle={"145"}
        />
        <Widget
          icon={<IoMdHome className="h-6 w-6" />}
          title={"Total Projects"}
          subtitle={"$2433"}
        /> */}
      </div>
      <div className="min-h-screen bg-cover bg-center dark:bg-navy-900 h-full mt-5">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 gap-4 px-2 mb-4">
          <div className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:bg-navy-700 dark:shadow-none w-full sm:w-4/6 lg:w-full">
            <ScreenWidget />
          </div>
          <div className="flex flex-col justify-center rounded-2xl drop-shadow-2xl w-full sm:w-4/6 lg:w-full bg-white pl-3 pb-4 dark:!bg-navy-800">
            <h5 className="text-center text-xl font-bold leading-9 text-navy-700 dark:text-white">Current Plan</h5>
            <div className='prices text-blue-500 dark:text-white text-2xl md:text-xl lg:text-3xl bg-black font-bold flex items-center justify-center'>
              $0.00
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 gap-4 px-2">
          <TaskCard />

          <div className="flex flex-col rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:bg-navy-700 dark:shadow-none w-full sm:w-4/6 lg:w-full">
            {/* <h5 className="text-center text-xl font-bold leading-9 text-navy-700 dark:text-white">Economic Calendar</h5> */}
            <EconomicCalender />
          </div>
        </div>
      </div>


    </>
  );
}

