import React, { useState, useEffect } from 'react';
import { Routes, Route, Navigate, Outlet, useNavigate } from 'react-router-dom';
import AdminLayout from 'layouts/admin';
// import Home from './home/Home';
// import About from 'about/About';
// import Pricing from 'Pricing/Pricing';
// import Affiliate from 'Affiliate/Affiliate';
import { Login } from 'Login';
import Signup from 'Signup';
import ForgotPassword from 'ForgotPassword';
import { APP_ROUTES } from './utils/constants';
import Notfound from 'Notfound';

const App = () => {
  const [authenticated, setAuthenticated] = useState(false);
  const navigate = useNavigate();



  const handleLogin = (token) => {
    localStorage.setItem('token', token);
    setAuthenticated(true);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    setAuthenticated(false);
    navigate(APP_ROUTES.LOGIN);
  };


  // Check authentication status on component mount
  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    setAuthenticated(!!storedToken);

  }, []);

  // Intercept navigation before it occurs
  const onBeforeNavigate = (e) => {
    // If not authenticated and trying to access a dashboard route, navigate to login
    if (!authenticated && e.pathname.startsWith('/dashboard/')) {
      navigate(APP_ROUTES.LOGIN);
      return false; // prevent default navigation
    }
    return true; // allow default navigation
  };


  return (
    <>
      <Routes onBeforeNavigate={onBeforeNavigate}>
        <Route path="/" element={<Outlet />}>
          <Route  element={<Navigate to="https://syntiumalgo.com" />} />
          <Route path="/" element={<Navigate replace to="/login" />} />
          <Route path="signup" element={<Signup />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          {/* <Route index element={<Home />} /> 
          <Route path="about-us" element={<About />} />
          <Route path="pricing" element={<Pricing />} />
          <Route path="affiliate-guide" element={<Affiliate />} /> */}
          <Route path="PAGE_NOT_FOUND" element={<Notfound />} />
          <Route path="login" element={<Login onLogin={handleLogin} />} />
          {authenticated ? (
            <Route path="dashboard/*" element={<AdminLayout />} />
          ) : (
            <>
              <Route path="dashboard/*" element={<Navigate to={APP_ROUTES.LOGIN} />} />
              {/* Redirect to login for any sub-routes of dashboard */}
              <Route path="dashboard" element={<Navigate to={APP_ROUTES.LOGIN} />} />
            </>
          )}
        </Route>
      </Routes>
    </>
  );
};

export default App;