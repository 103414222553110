import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import Navbar from 'navbar/Navbar';
import { signInAsyc } from "./redux-toolkit/store/reducers/auth.reducer";
import { useDispatch } from "react-redux";
import { signInSchema } from "./redux-toolkit/config/validations";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { APP_ROUTES } from "./utils/constants";
import Mainfooter from 'footer/Mainfooter';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

export const Login = ({ onLogin }) => {

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { handleSubmit, control } = useForm({
    resolver: yupResolver(signInSchema)
  });

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      const sendData = {
        email: data.email,
        password: data.password,
      };
      const response = await dispatch(signInAsyc(sendData));
      const token = response.token;
      localStorage.setItem("token", token);
      setLoading(false);
      navigate(APP_ROUTES.DASHBOARD);
      window.location.reload(true);
      Swal.fire({
        icon: 'success',
        title: 'Login successful!',
        showConfirmButton: false,
        timer: 1500,
      });
    } catch (error) {
      console.error(error);
      setLoading(false);
      Swal.fire({
        icon: 'error',
        title: 'Login failed',
        text: 'Please check your email and password.',
      });
    }
  };

  return (
    <>
      <Navbar />
      <div className="bg-[#0a0921] h-screen">
        <div className='px-7'>
          <p className='text-white text-end'>Don't have an account?
            <Link to="/signup">
              <span className='font-bold px-2'>
                Signup here
                <svg className='h-4 w-5 font-bold inline-block' fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"></path>
                </svg>
              </span>
            </Link>
          </p>
        </div>
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-white">
              Login to your account
            </h2>
          </div>
          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <form onSubmit={handleSubmit(onSubmit)} className="space-y-6" >
              {/* <div className="flex items-center justify-center bg-[#0a0921]">
                <button className="px-4 py-2 border gap-2 flex justify-center items-center border-slate-200 dark:border-slate-700 rounded-lg text-slate-700 dark:text-slate-200 hover:border-slate-400 dark:hover:border-slate-500 hover:text-slate-900 dark:hover:text-slate-300 hover:shadow transition duration-150 uppercase font-bold w-full">
                  <img
                    className="w-6 h-6"
                    src="https://www.svgrepo.com/show/475656/google-color.svg"
                    loading="lazy"
                    alt="google logo"
                  />
                  <span className="text-white">Login with Google</span>
                </button>
              </div>
              <p className="text-center text-white font-bold">or</p> */}
              <div>
                <label htmlFor="email" className="block text-sm font-medium leading-6 text-white">
                  Email address
                </label>
                <div className="mt-2">
                  <Controller
                    name="email"
                    control={control}
                    render={({ field }) => (
                      <input
                        {...field}
                        id="email"
                        name="email"
                        placeholder="Email"
                        type="email"
                        autoComplete="email"
                        required
                        className="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    )}
                  />
                </div>
              </div>
              <div>
                <div className="flex items-center justify-between">
                  <label htmlFor="password" className="block text-sm font-medium leading-6 text-white">
                    Password
                  </label>
                </div>
                <div className="mt-2">
                  <Controller
                    name="password"
                    control={control}
                    render={({ field }) => (
                      <input
                        {...field}
                        id="password"
                        name="password"
                        type="password"
                        placeholder="Password"
                        autoComplete="current-password"
                        required
                        className="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    )}
                  />
                </div>
              </div>
              <div className="text-sm text-center">
                <Link to="/forgot-password" className="font-semibold text-white">
                  Don't remember your password?
                </Link>
              </div>
              <div>
                <button
                  className="
                     flex w-full justify-center rounded-md bg-white px-3 py-1.5 text-sm font-semibold leading-6 text-black shadow-sm hover:bg-red-500 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  type="submit"
                >
                  {loading ? <div className="mr-2 w-5 h-5 border-l-2 rounded-full animate-spin" /> : null}
                  <span>SIGN IN</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Mainfooter />
    </>
  );
};
