import {postRequest, putRequest, getRequest, patchRequest} from "../../config/axiosConfig";
import { setToken,setUser} from "../slices/auth.slice";

const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL

export function signInAsyc(data) {
    return async (dispatch, _getState) => {
        try {
            const res = await postRequest('api/v1/users/login', data);
            dispatch(setToken(res.token));
            dispatch(setUser(res.data.user));
            return res;
        } catch (error) {
            console.log(error);
            throw error;
        }
    };
}


export function signUpAsyc(data) {
    return async (dispatch, _getState) => {
        try {
            const res = await postRequest('/api/v1/users/signup', { ...data, phoneNumber: data.phoneNumber });
            dispatch(setUser(res.user))
            dispatch({ type: 'SET_PHONE_NUMBER', payload: data.phoneNumber })
            return res
        } catch (error) {
            console.log(error)
            throw error
        }
    }
}

export function signOutAsyc(){
    return async (dispatch , _getState ) => {
        try {
            localStorage.removeItem('token')
            localStorage.removeItem('user')
            dispatch(setToken(null))
            dispatch(setUser({
                user: {},
            }))
            return true
        }
        catch(error){
            console.log(error)
            throw error
        }
    }
}


export function getUserProfileAsync() {
    return async (dispatch, getState) => {
        try {
            const userToken = localStorage.getItem('token');
            const response = await fetch( REACT_APP_BASE_URL + '/api/v1/users/me', {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${userToken}`,
                    'Content-Type': 'application/json',
                },
            });

            if (response.ok) {
                const data = await response.json();
                dispatch(setUser(data.data.user));
                return data.data.data;
            } else {
                console.error('Error fetching user data:', response.status, response.statusText);
            }
        } catch (error) {
            console.error('Error fetching user data:', error.message);
        }
    };
}



export function updateUserProfileAsyc(data){
    return async ( _getState ) => {
        const res = await putRequest(`api/v1/users/updateme`,data)
        return res
    }
}


// google login
export function signInWitGoogleAsync(data) {
    return async (dispatch , _getState ) => {
        try {
            const res = await postRequest('auth/google/callback', data)
            dispatch(setToken(res.token))
            dispatch(setUser(res.user))
            return res
        } catch (error) {
            console.log(error)
            throw error
        }
    }
}
