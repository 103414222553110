import React from "react";
import MainDashboard from "views/admin/default";
import Profile from "views/admin/profile/Profile";
import Affiliate from "components/Affiliate/Affiliate";
import { Telegram } from "components/Telegram/Telegram";


import {
  MdHome,
  MdOutlineShoppingCart,
  MdBarChart,
  MdPerson
} from "react-icons/md";

const routes = [
  {
    name: "Main Dashboard",
    layout: "/dashboard",
    path: "default",
    icon: <MdHome className="h-6 w-6" />,
    component: <MainDashboard />,
  },
  // {
  //   name: "Affiliate",
  //   layout: "/dashboard",
  //   path: "affiliate",
  //   icon: <MdOutlineShoppingCart className="h-6 w-6" />,
  //   component: <Affiliate />,
  //   secondary: true,
  // },
  {
    name: "Telegram",
    layout: "/dashboard",
    icon: <MdBarChart className="h-6 w-6" />,
    path: "telegram",
    component: <Telegram />,
  },
  {
    name: "Profile",
    layout: "/dashboard",
    path: "profile",
    icon: <MdPerson className="h-6 w-6" />,
    component: <Profile />,
  },
];
export default routes;
