import React from "react";
// import TelegramBackground from "./img/telegrambackground.jpg";
import TelegramComponent from "./TelegramComponent";

export const Telegram = () => {
  return (
    <div className="min-h-screen bg-cover bg-center dark:bg-navy-900">
      <div className="text-navy-700 dark:text-white p-3">
       {/*  <button className="bg-blue-500 p-2 text-white justify-end">
          Download
        </button> */}
      </div>
      <div className="p-1 md:p-10">
        <TelegramComponent />
      </div>
    </div>
  );
};
