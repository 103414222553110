import React, { useState } from 'react';
import logo from './logo.png';
// import { Link } from 'react-router-dom';

export default function Navbar() {
  const [isDropdownOpen, setDropdownOpen] = useState(false);


  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  return (
    <nav className="bg-[#070515] sticky w-full bg-bgimage bg-cover p-4">
      <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto">
        <a href="https://syntiumalgo.com" className="flex items-center">
          <img src={logo} className="h-8 mr-3" alt="SyntiumAlgo Logo" />
        </a>
        <button
          onClick={toggleDropdown}
          type="button"
          className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-white dark:hover:bg-gray-700 dark:focus:ring-gray-600"
          aria-expanded={isDropdownOpen ? 'true' : 'false'}
        >
          <span className="sr-only">Open main menu</span>
          <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15" />
          </svg>
        </button>
        <div className={`w-full md:block md:w-auto ${isDropdownOpen ? 'block' : 'hidden'}`} id="navbar-dropdown">
          <ul className="flex flex-col font-medium p-4 md:p-0 mt-4 rounded-lg bg-[#070515] bg-bgimage bg-cover md:flex-row md:space-x-8 md:mt-0 md:border-0 md:bg-[#070515]">
           {/*  <li>
              <a href="/about-us" className="block py-2 pl-3 pr-4 text-white" aria-current="page">
                About us
              </a>
            </li>
            <li>
              <a href="/pricing" className="block py-2 pl-3 pr-4 text-white">
                Pricing
              </a>
            </li>
            <li>
              <a href="affiliate-guide" className="block py-2 pl-3 pr-4 text-white">
                Affiliate Program
              </a>
            </li>
            <li>
              <a href="/pricing" className="block py-2 pl-3 pr-4 text-white">
                <button type="button" class="bg-gradient-to-br from-[#0a0921] to-[#c7ace4] text-white border border-gray-50 rounded-lg px-4">
                  Buy
                  <svg className='h-4 w-8 font-bold inline-block text-white' fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"></path>
                  </svg>
                </button>
              </a>
            </li>*/}

            <li> 
              <a href="/login" className="block py-2 pl-3 pr-4 text-white">
                <span className='font-bold px-4 text-white text-base'>
                  Login
                </span>
              </a>
            </li>
            <li>
              <a href="/signup" className="block py-2 pl-3 pr-4 text-white">
                {/* <Link to='/PAGE_NOT_FOUND'> */}
                <span className='font-bold px-4 text-white text-base'>
                  Signup
                </span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}
