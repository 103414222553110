import React from 'react'

import format from "date-fns/format";
import getDay from "date-fns/getDay";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import { useState } from "react";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import DatePicker from "react-datepicker";
import enUS from "date-fns/locale/en-US";

// CSS
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-datepicker/dist/react-datepicker.css";


const locales = {
    "en-US": enUS,
};
const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
});


// dummy event data
const events = [
    {
        title: "Big Meeting",
        allDay: true,
        start: new Date(2021, 6 - 1, 0),
        end: new Date(2021, 6 - 1, 0),
    },
    {
        title: "Vacation",
        start: new Date(2021, 6 - 1, 7),
        end: new Date(2021, 6 - 1, 10),
    },
    {
        title: "Conference",
        start: new Date(2021, 6 - 1, 20),
        end: new Date(2021, 6 - 1, 23),
    },
];

function EconomicCalender() {
    const [newEvent, setNewEvent] = useState({ title: "", start: "", end: "" });
    const [allEvents, setAllEvents] = useState(events);

    function handleAddEvent() {

        for (let i = 0; i < allEvents.length; i++) {

            const d1 = new Date(allEvents[i].start);
            const d2 = new Date(newEvent.start);
            const d3 = new Date(allEvents[i].end);
            const d4 = new Date(newEvent.end);
            /*
                console.log(d1 <= d2);
                console.log(d2 <= d3);
                console.log(d1 <= d4);
                console.log(d4 <= d3);
                  */

            if (
                ((d1 <= d2) && (d2 <= d3)) || ((d1 <= d4) &&
                    (d4 <= d3))
            ) {
                alert("CLASH");
                break;
            }

        }


        setAllEvents([...allEvents, newEvent]);
    }

    return (
        <div className="calendar dark:text-white container mx-auto">
            <h1 className='text-center font-bold text-xl'>Calendar</h1>

            {/*ADD EVENT  */}
            <h2 className='font-bold dark:text-white'>Add New Event</h2>
            <div>
                <input type="text" placeholder="Add Title" className='text-sm' style={{ width: "20%", marginRight: "10px" }} value={newEvent.title} onChange={(e) => setNewEvent({ ...newEvent, title: e.target.value })} />
                <DatePicker className='text-sm text-white dark:text-white dark:bg-none' placeholderText="Start Date" style={{ marginRight: "10px" }} selected={newEvent.start} onChange={(start) => setNewEvent({ ...newEvent, start })} />
                <DatePicker className='text-sm text-white dark:text-white dark:bg-none' placeholderText="End Date" selected={newEvent.end} onChange={(end) => setNewEvent({ ...newEvent, end })} />
                <button style={{ marginTop: "10px" }} onClick={handleAddEvent} className='rounded text-white text-base bg-blue-500 w-[30%] md:w-[30%] lg-w-[20%]'>
                    Add Event
                </button>
            </div>
            {/* CALENDAR */}
            <Calendar localizer={localizer} events={allEvents} startAccessor="start" endAccessor="end" style={{ height: 400, margin: "30px" }} className='text-base' />
        </div>
    );
}
export default EconomicCalender;