import React from 'react'

export default function Mainfooter() {
  return (
    <div>
      <footer className="w-full lg:w-full bg-[#070515] text-white pt-5">
        <div className="mx-auto w-full max-w-screen p-4 py-6 lg:py-8 px- md:px-2 lg:px-10">
          <div className="md:flex md:justify-between">
            <div className="mb-6 md:mb-0">
              <a href="https://syntiumalgo.com/" className="flex items-center">
                <p className="h-8 mr-3 " alt="FlowBite Logo"></p>
                <span className="self-center text-xl pb-3 font-semibold whitespace-nowrap dark:text-white uppercase">About us</span>
              </a>
              <p className='text-sm md:text-sm lg:text-base'>
                Trading is risky, and most day traders lose money. <br />
                SyntiumAlgo provides information and education, not<br />
                financial advice. Decisions regarding securities, <br />
                commodities, and investments are best made with guidance <br />
                from qualified financial professionals. Past <br />
                performance does not ensure future results.<br />
              </p>
            </div>
            <div className="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-2">
              <div>
                <h2 className="self-center text-xl md:text-sm lg:text-xl pb-3 font-semibold whitespace-nowrap dark:text-white uppercase">CONNECT WITH US</h2>
                <ul className="text-gray-500 dark:text-gray font-medium flex space-x-4">
                  <li className="mb-4">
                    <a href="https://flowbite.com/" className="hover:underline">
                      <svg className="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 21 16">
                        <path d="M16.942 1.556a16.3 16.3 0 0 0-4.126-1.3 12.04 12.04 0 0 0-.529 1.1 15.175 15.175 0 0 0-4.573 0 11.585 11.585 0 0 0-.535-1.1 16.274 16.274 0 0 0-4.129 1.3A17.392 17.392 0 0 0 .182 13.218a15.785 15.785 0 0 0 4.963 2.521c.41-.564.773-1.16 1.084-1.785a10.63 10.63 0 0 1-1.706-.83c.143-.106.283-.217.418-.33a11.664 11.664 0 0 0 10.118 0c.137.113.277.224.418.33-.544.328-1.116.606-1.71.832a12.52 12.52 0 0 0 1.084 1.785 16.46 16.46 0 0 0 5.064-2.595 17.286 17.286 0 0 0-2.973-11.59ZM6.678 10.813a1.941 1.941 0 0 1-1.8-2.045 1.93 1.93 0 0 1 1.8-2.047 1.919 1.919 0 0 1 1.8 2.047 1.93 1.93 0 0 1-1.8 2.045Zm6.644 0a1.94 1.94 0 0 1-1.8-2.045 1.93 1.93 0 0 1 1.8-2.047 1.918 1.918 0 0 1 1.8 2.047 1.93 1.93 0 0 1-1.8 2.045Z" />
                      </svg>
                    </a>
                  </li>

                  <li>
                    <a href="https://t.me/syntiumalgofree" className="hover:underline">
                      <svg className="w-4 h-4 gray-red-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" x="0px" y="0px" width="100" height="100" viewBox="0 0 50 50">
                        <path d="M46.137,6.552c-0.75-0.636-1.928-0.727-3.146-0.238l-0.002,0C41.708,6.828,6.728,21.832,5.304,22.445	c-0.259,0.09-2.521,0.934-2.288,2.814c0.208,1.695,2.026,2.397,2.248,2.478l8.893,3.045c0.59,1.964,2.765,9.21,3.246,10.758	c0.3,0.965,0.789,2.233,1.646,2.494c0.752,0.29,1.5,0.025,1.984-0.355l5.437-5.043l8.777,6.845l0.209,0.125	c0.596,0.264,1.167,0.396,1.712,0.396c0.421,0,0.825-0.079,1.211-0.237c1.315-0.54,1.841-1.793,1.896-1.935l6.556-34.077	C47.231,7.933,46.675,7.007,46.137,6.552z M22,32l-3,8l-3-10l23-17L22,32z"></path>
                      </svg>
                    </a>
                  </li>

                </ul>
              </div>
              <div>
                <h2 className="self-center text-xl pb-3 font-semibold whitespace-nowrap dark:text-white uppercase">contact us</h2>
                <ul className="text-gray-500 dark:text-gray-400 font-bold text-sm md:text-sm lg:text-base">
                  <li className="mb-4">
                    <a href="mailto:support@syntiumalgo.com" className="hover:underline flex items-center space-x-6">
                      <svg className="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                        <path d="M0 0h24v24H0z" fill="none"/>
                        <path d="M20 4H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2zm0 2v3.586L12 13.586 4 9.586V6h16zM4 17v2h16v-2H4z"/>
                      </svg>
                      &nbsp; support@syntiumalgo.com
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
          <div className="sm:flex sm:items-center sm:justify-between">
            <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">© 2023 <a href="https://syntiumalgo.com/" className="hover:underline">SyntiumAlgo™</a>. All Rights Reserved.
            </span>
          </div>
        </div>
      </footer>

    </div>
  )
}