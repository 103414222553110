import Mainfooter from 'footer/Mainfooter';
import Navbar from 'navbar/Navbar';
import React from 'react';

export default function Notfound() {
  return (
    <>
      <Navbar />
      <div className='bg-[#070515] bg-divbg2 bg-cover lg:px-8 md:px-4 sm:px-2 pt-6'>
        <div className='justify-center items-center container flex flex-wrap max-w-6xl mx-auto w-full h-screen bg-design'>

          <div className="w-full flex-shrink-0 max-w-6xl mx-auto">
            <p className='text-3xl md:text-3xl lg:text-7xl text-white font-bold pb-5 text-center'>PAGE NOT FOUND</p>

          </div>
        </div>
      </div>
      <Mainfooter />
    </>
  );
}