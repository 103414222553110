import React from 'react'
import { Link } from 'react-router-dom'
import Navbar from 'navbar/Navbar';
export default function ForgotPassword() {
  return (
    <>
    <Navbar />
      <div className='bg-[#0a0921] pt-20 sm:pt-10 md:pt-15 lg:pt-20'>
      <div className='px-7 pt-2'>
        <p className='text-white text-end'>Don’t have an account?
          <Link to="/signup">
            <span className='font-bold px-2'>
              Sign up
              <svg className='h-4 w-5 font-bold inline-block' fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"></path>
              </svg>
            </span>
          </Link>
        </p>
      </div>
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <h2 className="pt-5 text-center text-2xl font-bold leading-9 tracking-tight text-white">
            Forgot password
          </h2>
          <p className='text-xl font-bold text-white text-center'>We will send password reset link on your email Id</p>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form className="space-y-6" action="#" method="POST">

            <div>
              <label htmlFor="email" className="block text-sm font-medium leading-6 text-white">
                Email ID
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  placeholder='Enter the email adress associated with your account'
                  type="email"
                  autoComplete="email"
                  required
                  className="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="text-sm text-center">
              <a href="/signup" className="font-semibold text-blue">
                Don't have an account yet? Register here
              </a>
            </div>
            <div>
              <a href="#" className="flex w-full justify-center rounded-md bg-white px-3 py-1.5 text-sm font-semibold leading-6 text-black shadow-sm hover:bg-red-500 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 uppercase">
                send reset link
              </a>
            </div>
          </form>
        </div>
      </div>
    </div >
    </>
  )
}