import React from 'react'

export default function ScreenWidget() {
    return (
        <>
            <div className="container p-2 mx-auto sm:p-4 dark:text-white">
                <h2 className="mb-4 text-2xl font-semibold leadi">Your referrals</h2>
                <div className="overflow-x-auto">
                    <table className="min-w-full text-xs">
                        <colgroup>
                            <col />
                            <col />
                            <col />
                            {/* <col /> */}
                            <col />
                            <col className="w-24" />
                        </colgroup>
                        <thead className="bg-gray-200 dark:bg-blue-500">
                            <tr className="text-left">
                                <th className="p-3">Ref no #</th>
                                <th className="p-3">Name</th>
                                <th className="p-3">Referral date</th>
                                <th className="p-3 text-right">Commission</th>
                                <th className="p-3">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="border-b border-opacity-20 dark:border-gray-700 dark:bg-gray-900">
                                <td className="p-3">
                                    <p>1</p>
                                </td>
                                <td className="p-3 text-red-500 font-bold">
                                    <p>You don't have any referrals</p>
                                </td>
                                <td className="p-3">
                                    <p>N/A</p>
                                    <p className="dark:text-gray-400">N/A</p>
                                </td>
                                <td className="p-3 text-right">
                                    <p>$0.00</p>
                                </td>
                                <td className="p-3 text-right">
                                    <span className="px-3 py-1 font-semibold dark:bg-violet-400 dark:text-white rounded-xl bg-red-400">
                                        <span>Pending</span>
                                    </span>
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}
