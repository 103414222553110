import React, { useState, useEffect } from 'react';
import { getUserProfileAsync } from "../../../redux-toolkit/store/reducers/auth.reducer";
import { useDispatch } from "react-redux";

const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL

const Profile = () => {
  const dispatch = useDispatch();
  const [user, setUser] = useState(null);
  const [fullName, setFullName] = useState('');
  const [username, setUserName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [photo, setPhoto] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await dispatch(getUserProfileAsync());
        setUser(response);
        setFullName(response?.fullName || '');
        setUserName(response?.username || '');
        setEmail(response?.email || '');
        setPhoto(response?.photo || '');
        setPassword(response?.password || '');
        setNewPassword(response?.newPassword || '');
        setConfirmPassword(response?.confirmPassword || '');
      } catch (error) {
        console.error('Error fetching user data:', error.message);
      }
    };
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    setFullName(user?.fullName || '');
    setUserName(user?.username || '');
    setEmail(user?.email || '');
    setPhoto(user?.photo || '');
    setPassword(user?.password || '');
    setNewPassword(user?.newPassword || '');
    setConfirmPassword(user?.confirmPassword || '');
  }, [user]);

  const handleNameChange = (e) => setFullName(e.target.value);
  const handleUserNameChange = (e) => setUserName(e.target.value);
  const handleEmailChange = (e) => setEmail(e.target.value);
  const handlePasswordChange = (e) => setPassword(e.target.value);
  const handleNewPasswordChange = (e) => setNewPassword(e.target.value);
  const handleConfirmPasswordChange = (e) => setConfirmPassword(e.target.value);

  const handleAvatarChange = (e) => {
    const selectedFile = e.target.files[0];
    setPhoto(selectedFile);
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData();
    formData.append('photo', photo);

    const sendData = {
      fullName,
      username,
      email,
      password,
      newPassword,
      confirmPassword,
    };

    const userToken = localStorage.getItem('token');

    if (photo) {
      if (typeof photo === 'string') {
        sendData.photo = photo;
      } else {
        formData.append('photo', photo);
      }
    }

    const commonHeaders = {
      'Authorization': `Bearer ${userToken}`,
    };

    try {
      const updateUserResponse = await fetch(REACT_APP_BASE_URL + '/api/v1/users/updateme', {
        method: 'PATCH',
        headers: {
          ...commonHeaders,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(sendData),
      });

      if (!updateUserResponse.ok) {
        throw new Error(`HTTP error! Status: ${updateUserResponse.status}`);
      }

      const updateUserData = await updateUserResponse.json();
      console.log('User Update Success:', updateUserData);

      if (photo) {
        const uploadPhotoResponse = await fetch( REACT_APP_BASE_URL + '/api/v1/users/updateMe', {
          method: 'PATCH',
          headers: {
            ...commonHeaders,
          },
          body: formData,
        });

        if (!uploadPhotoResponse.ok) {
          throw new Error(`HTTP error! Status: ${uploadPhotoResponse.status}`);
        }

        const uploadPhotoData = await uploadPhotoResponse.json();
        console.log('Photo Upload Success:', uploadPhotoData);
      }
    } catch (error) {
      console.error('Error:', error.message);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleUpdate} className="max-w-2xl mx-auto mt-8 p-6 bg-white rounded-md shadow-md">
      <h2 className="text-3xl font-semibold mb-6 text-center text-gray-800">Edit Profile</h2>
      <div className="flex items-center justify-center mb-6">
        <div className="relative">
          <img
            src={typeof photo === 'string' ? photo : (photo instanceof File ? URL.createObjectURL(photo) : null)}
            alt="Profile"
            className="w-20 h-20 rounded-full object-cover"
          />
          <input
            type="file"
            id="avatar"
            name="avatar"
            accept="image/*"
            className="hidden"
            onChange={handleAvatarChange}
          />
          <label
            htmlFor="photo"
            className="absolute bottom-0 right-0 bg-blue-500 text-white rounded-full p-2 cursor-pointer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 21a2 2 0 01-2 2H7a2 2 0 01-2-2V5a2 2 0 012-2h3l2-3h4l2 3h3a2 2 0 012 2v14z"
              />
              <circle cx="12" cy="12" r="3" />
            </svg>
          </label>
        </div>
      </div>

      <div className="bg-gray-100 p-4 rounded-md mb-6">
        <h3 className="text-xl font-semibold mb-4 text-gray-700">Profile Information</h3>
        <div className="space-y-4">
          <div>
            <label htmlFor="fullName" className="block text-sm font-medium text-gray-600">
              Full Name
            </label>
            <input
              type="text"
              id="fullName"
              name="fullName"
              value={fullName}
              onChange={handleNameChange}
              className="mt-1 p-3 w-full border rounded-md focus:outline-none focus:ring focus:border-blue-300"
            />
          </div>

          <div>
            <label htmlFor="username" className="block text-sm font-medium text-gray-600">
              Username
            </label>
            <input
              type="text"
              id="username"
              value={username}
              onChange={handleUserNameChange}
              name="username"
              className="mt-1 p-3 w-full border rounded-md focus:outline-none focus:ring focus:border-blue-300"
            />
          </div>

          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-600">
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={handleEmailChange}
              className="mt-1 p-3 w-full border rounded-md focus:outline-none focus:ring focus:border-blue-300"
            />
          </div>
        </div>
      </div>

      <div className="bg-gray-100 p-4 rounded-md">
        <h3 className="text-xl font-semibold mb-4 text-gray-700">Password Reset</h3>

        <div className="space-y-4">
          <div>
            <label htmlFor="currentPassword" className="block text-sm font-medium text-gray-600">
              Current Password
            </label>
            <input
              type="password"
              id="currentPassword"
              name="currentPassword"
              value={password}
              onChange={handlePasswordChange}
              className="mt-1 p-3 w-full border rounded-md focus:outline-none focus:ring focus:border-blue-300"
            />
          </div>

          <div>
            <label htmlFor="newPassword" className="block text-sm font-medium text-gray-600">
              New Password
            </label>
            <input
              type="password"
              id="newPassword"
              name="newPassword"
              value={newPassword}
              onChange={handleNewPasswordChange}
              className="mt-1 p-3 w-full border rounded-md focus:outline-none focus:ring focus:border-blue-300"
            />
          </div>

          <div>
            <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-600">
              Confirm Password
            </label>
            <input
              type="password"
              id="confirmPassword"
              name="confirmPassword"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              className="mt-1 p-3 w-full border rounded-md focus:outline-none focus:ring focus:border-blue-300"
            />
          </div>
        </div>
      </div>

      <div className="flex justify-center mt-8">
        <button
          type="submit"
          className="bg-blue-500 text-white px-6 py-3 rounded-md hover:bg-blue-600 focus:outline-none focus:ring focus:border-blue-300"
        >
          Save Changes
        </button>

        <button
          type="submit"
          className="ml-4 bg-red-500 text-white px-6 py-3 rounded-md hover:bg-red-600 focus:outline-none focus:ring focus:border-red-300">
          Reset Password
        </button>
      </div>
    </form>
  );
};

export default Profile;
